// @flow

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// $FlowFixMe
import config from '@/agencies-config'
import { Client, User } from '@/models'
import { NavigationAnchor } from '@/components'
import classnames from '@/helpers'
import { isClientList, isTermsOfUse, isPrivacyNotice, isPortalRoute } from '@/helpers/routeChecking'
import selectors from '@/selectors'
import { Strings, Images, Routes } from '@/constants'
import { ActionCreators } from '@/redux'

import styles from './styles.scss'
import HeaderMenu from './header-menu'

type StateToPropsType = {|
  user: ?User,
  activeClient: ?Client | User,
|}

type DispatchProps = {|
  logoutUser: () => void,
|}

type HeaderProps = {|
  ...StateToPropsType,
  ...DispatchProps,
  location: Object,
|}

export class Header extends React.Component<HeaderProps> {
  logout = () => {
    const { logoutUser } = this.props
    logoutUser()
  }

  renderUserInfo() {
    const { user, activeClient, location } = this.props

    const isActiveClientVisible = !(
      isClientList(location.pathname) ||
      isTermsOfUse(location.pathname) ||
      isPrivacyNotice(location.pathname)
    )

    if (!user) {
      return null
    }

    if (isActiveClientVisible) {
      return (
        <div className={styles.advisorAndClientWrap}>
          <NavigationAnchor
            ariaLabel={Strings.permissionedClientList(user.displayName)}
            label={Strings.permissionedClientList(user.displayName)}
            path={Routes.portal.ClientList}
            className={styles.advisorNameLink}
          />
          <p className={styles.clientName}>{Strings.client(activeClient?.displayName || '')}</p>
        </div>
      )
    }

    return (
      <div className={styles.userNameWrap}>
        <NavigationAnchor
          ariaLabel={user.displayName}
          label={user.displayName}
          path={Routes.portal.ClientList}
          className={styles.userName}
        />
      </div>
    )
  }

  renderMainHeader() {
    const { user, location } = this.props
    const { pathname } = location
    const inPortal = isPortalRoute(pathname)

    return (
      <section className={classnames(styles.publicHeader)}>
        <h1>
          <a href={config.landingPageURL} target="_blank" rel="noopener noreferrer">
            <img fetchpriority="high" alt="Home" src={Images.logoHorizontal} />
          </a>
        </h1>

        {user && inPortal && (
          <>
            {this.renderUserInfo()}
            <HeaderMenu />
            <button className={styles.userAction} type="button" onClick={this.logout}>
              {Strings.logout}
            </button>
          </>
        )}
      </section>
    )
  }

  render() {
    return <header className={styles.pageHeader}>{this.renderMainHeader()}</header>
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
  activeClient: selectors.user.getActiveClient(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  logoutUser: payload => dispatch(ActionCreators.user.clearUserSession.dispatch(payload)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
