// @flow

import React from 'react'
import { connect } from 'react-redux'

import selectors from '@/selectors'

import { Strings, Images } from '@/constants'

import styles from './styles.scss'
import classnames from '@/helpers'
import modal from '@/components/modal/modal'
import HeaderSessionsList from '../header-sessions-list'

type HeaderMenuState = {|
  isMenuOpen: boolean,
|}

type StateToPropsType = {|
  user: ?User,
|}

type HeaderMenuProps = {|
  ...StateToPropsType,
|}

class HeaderMenu extends React.Component<HeaderMenuProps, HeaderMenuState> {
  state = {
    isMenuOpen: false,
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen,
    }))
  }

  hideMenu = () => {
    this.setState({
      isMenuOpen: false,
    })
  }

  openPortalSettings = () => {
    modal.settings()
    this.hideMenu()
  }

  renderMenuThumb() {
    const { isMenuOpen } = this.state
    return (
      <button
        className={classnames(styles.menuThumb, isMenuOpen ? styles.menuThumbExpanded : '')}
        onClick={this.toggleMenu}
        type="button"
      >
        <img src={Images.settings} alt={Strings.settings.title} />
        <img
          className={classnames(styles.menuThumbArrow)}
          src={Images.chevronUp}
          alt={Strings.settings.title}
        />
      </button>
    )
  }

  renderMenuUser() {
    const { user } = this.props
    const firstLetter = user.displayName.charAt(0).toUpperCase()
    return (
      <div className={styles.menuUser}>
        <div className={styles.menuUserAvatar}>{firstLetter}</div>
        <div>
          <b>{user.displayName}</b>
          <span>{user.email}</span>
        </div>
      </div>
    )
  }

  renderMenuContent() {
    return (
      <div className={styles.menuContent}>
        {this.renderMenuUser()}
        <button
          className={classnames(styles.menuItem, styles.menuItemDark)}
          onClick={this.openPortalSettings}
          type="button"
        >
          <img src={Images.settingsGray} alt={Strings.settings.title} />
          {Strings.settings.title}
        </button>
        <HeaderSessionsList onLinkClick={this.hideMenu} />
      </div>
    )
  }

  render() {
    const { isMenuOpen } = this.state
    return (
      <div className={styles.headerMenu}>
        {this.renderMenuThumb()}
        {isMenuOpen && this.renderMenuContent()}
      </div>
    )
  }
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
})

export default connect(mapStateToProps)(HeaderMenu)
